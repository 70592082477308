import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Nav = ({ onCategory, onBrand }) => {
  const navigate = useNavigate();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isCNDropdownVisible, setCNDropdownVisible] = useState(false);
  const [isKRDropdownVisible, setKRDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleCNMouseEnter = () => {
    setCNDropdownVisible(true);
  };
  const handleCNMouseLeave = () => {
    setCNDropdownVisible(false);
  };

  const handleKRMouseEnter = () => {
    setKRDropdownVisible(true);
  };
  const handleKRMouseLeave = () => {
    setKRDropdownVisible(false);
  };

  console.log("isDropdownVisible", isDropdownVisible, isCNDropdownVisible, isKRDropdownVisible)


  return (
    <nav className="fixed w-full bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
      <div className="flex items-center justify-between mx-auto">
        {/* Image */}
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            className="w-20 ml-3"
            src="https://s3.amazonaws.com/products.naganorewards.com/logos/Nagano%2Blogo.png"
            alt="Nagano Rewards"
          />
        </a>
        {/* Button and Link Container */}
        <div
          style={{ height: "78px" }}
          className="flex items-center space-x-8 mr-8 "
         >
          {/* Products Button */}
          <button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            // style={{ backgroundColor: "red" }}
            className="h-full flex items-center py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            JP
          </button>

          <button
            onMouseEnter={handleCNMouseEnter}
            onMouseLeave={handleCNMouseLeave}
            className="h-full flex items-center py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            CN
          </button>

          <button
            onMouseEnter={handleKRMouseEnter}
            onMouseLeave={handleKRMouseLeave}
            className="h-full flex items-center py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            KR
          </button>
          {/* CSV Link */}
          {/* <a
            href="csv"
            className="block py-2 px-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            CSV
          </a> */}
        </div>
      </div>

      {/* Dropdown menu on Hover */}
      {isDropdownVisible && (
        <div
          onMouseEnter={handleMouseEnter} // Keep the dropdown visible when hovering inside
          onMouseLeave={handleMouseLeave} // Close the dropdown when mouse leaves the entire navigation container
          id="dropdownHover"
          className="max-h-80 w-full border-gray-200 shadow-sm pb-10 pt-8 px-10 bg-gray-50 border-y"
        >
          <div className="max-h-80 grid grid-cols-5 ml-10 text-gray-900 dark:text-white">
            <ul>
              <div className="font-bold text-sm mb-2">By Category</div>
              {jpCategories.map((c) => (
                <li
                  key={c}
                  onClick={() => {
                    onCategory(c);
                    onBrand("View All");
                    navigate("/");
                  }}
                >
                  <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                    {c}
                  </span>
                </li>
              ))}
            </ul>
            <ul>
              <div className="font-bold text-sm mb-2">By Brand</div>
              {jpBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(0, 11)}
            </ul>
            <ul className="mt-7">
              {jpBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(11, 22)}
            </ul>
            <ul className="mt-7">
              {jpBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(22, 33)}
            </ul>
            <ul className="mt-7">
              {jpBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(33, 46)}
            </ul>
          </div>
        </div>
      )}

      {isCNDropdownVisible && (
        <div
          onMouseEnter={handleCNMouseEnter} // Keep the dropdown visible when hovering inside
          onMouseLeave={handleCNMouseLeave} // Close the dropdown when mouse leaves the entire navigation container
          id="dropdownHover"
          className="max-h-80 w-full border-gray-200 shadow-sm pb-10 pt-8 px-10 bg-gray-50 border-y"
        >
          <div className="max-h-80 grid grid-cols-6 ml-10 text-gray-900 dark:text-white">
            <ul>
              <div className="font-bold text-sm mb-2">By Brand</div>
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(0, 11)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(11, 22)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(22, 33)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(33, 44)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(44, 55)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(55, 66)}
            </ul>
            <ul className="mt-7">
              {cnBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(66, 77)}
            </ul>
          </div>
        </div>
      )}

      {isKRDropdownVisible && (
        <div
          onMouseEnter={handleKRMouseEnter} // Keep the dropdown visible when hovering inside
          onMouseLeave={handleKRMouseLeave} // Close the dropdown when mouse leaves the entire navigation container

          id="dropdownHover"
          className="max-h-80 w-full border-gray-200 shadow-sm pb-10 pt-8 px-10 bg-gray-50 border-y"
        >
          <div className="max-h-80 grid grid-cols-6 ml-10 text-gray-900 dark:text-white">
            <ul>
              <div className="font-bold text-sm mb-2">By Brand</div>
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(0, 11)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(11, 22)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(22, 33)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(33, 44)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(44, 55)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(55, 66)}
            </ul>
            <ul className="mt-7">
              {krBrands
                .map((b) => (
                  <li
                    key={b}
                    onClick={() => {
                      onBrand(b);
                      onCategory("View All");
                      navigate("/");
                    }}
                  >
                    <span className="cursor-pointer text-sm block pl-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
                      {b}
                    </span>
                  </li>
                ))
                .slice(66, 77)}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;

export const jpCategories = [
  "View All",
  "New Arrivals",
  "Cosmetics & Beauty",
  "Electronics",
  "Gift",
  "Gift Card",
  "Health & Beauty",
  "Home & Garden",
  "Jewelry & Watches",
  "Leisure",
  "Personal Care",
  "Travel",
];

export const jpBrands = [
  "View All",
  "Apple",
  "audio-technica",
  "Carson",
  "Beats",
  "Bodum",
  "Bose",
  "BRUNO",
  "BULOVA",
  "Citizen",
  "DANSK",
  "De'Longhi",
  "Dyson",
  "EMEET",
  "evercook",
  "Fossil",
  "FUJIHORO",
  "GARMIN",
  "Hyperice",
  "IMABARI KINSEI",
  "iRobot",
  "KAERCHER",
  "KITAO",
  "Le Creuset",
  "Makita",
  "NECESPOW",
  "Nintendo",
  "Philips",
  "Philips Hue",
  "RHYTHM",
  "RUHAKU",
  "Samsonite",
  "Seiko",
  "SHARP",
  "SORI YANAGI",
  "sutto",
  "Swarovski",
  "T-fal",
  "Tanita",
  "Victorinox",
  "VOICE CADDIE",
  "Yogibo",
  "ZMI",
  // "ZOJIRUSHI",
];
// 43 jpBrands

export const cnBrands = [
  // "View All",
  "AIRMATE",
  "APM Monaco",
  "ASVEL",
  "B&O",
  "BABOLAT",
  "BAVAY",
  "Bear",
  "beneunder",
  "Bicoy",
  "CAMEL",
  "COACH",
  "crocs",
  "DanielWellington",
  "deli",
  "DHS",
  "Diplomat",
  "DISNEY",
  "EDIFIER",
  "EETOYS",
  "EPSON",
  "FUANNA",
  "GARMIN",
  "GREE",
  "GUCCI",
  "HiVi",
  "Homerun",
  "hoopet",
  "HUAWEI",
  "HYPERICE",
  "JAGO",
  "JBL",
  "Joyoung",
  "LEGO",
  "Lenovo",
  "LOCK&LOCK",
  "LOVEYOUPET",
  "MARK RYDEN",
  "MI",
  "Midea",
  "MiDeer",
  "MIJIA",
  "MOBIGARDEN",
  "Morphyrichards",
  "OIWAS",
  "Panasonic",
  "PHILIPS",
  "pidan",
  "Samsonite",
  "SMEG",
  "SPALDING",
  "Star",
  "staub",
  "Supor",
  "TAFUCO",
  "THERAGUN",
  "TILIVING",
  "TOMIC",
  "tudao",
  "TUMI",
  "VICTORIATOURIST",
  "WANGYEJUN",
  "Wilson",
  "YIHUTEA",
  "YONEX",
  "ZEZE",
  "ZWILLING",
];

export const krBrands = [
  "Amorepacific",
  "ATOCARE",
  "Baswell",
  "BFL",
  "bokuk",
  "Calvin klein",
  "Celltrion",
  "Cheong Kwan Jang",
  "Cheongsoom",
  "CHUNHONCARE",
  "CJ",
  "Cookcell",
  "CUCKOO",
  "Daeng Gi Meo Ri",
  "Dermagen",
  "DOBIDOS",
  "Dongjin Pharmaceutical",
  "DongWon",
  "Dr.G",
  "ELBA",
  "ELCANTO",
  "FDiary",
  "GIPFEL",
  "HAAN",
  "Hanil",
  "HANSSEM",
  "HAPPYCALL",
  "HuBDIC",
  "Hugo-Frosch",
  "IBLE",
  "Inkel",
  "Iriver",
  "Kitchen-Art",
  "LG H&H",
  "LOUIS QUATORZE",
  "MICHELANGELO",
  "Novita",
  "Orthomol",
  "PENSONIC",
  "PGATOUR",
  "Philips",
  "Polaroid",
  "PUMA",
  "PURADAK",
  "Roberta_dicamerino",
  "Samsonite",
  "SHINIL",
  "Shinsegae Food",
  "SHUMAX",
  "SIDIZ",
  "Starbucks",
  "TANDY",
  "Tefal",
  "Tender garden",
  "TESEUMGOLF",
  "WMF",
  "Xiaomi",
];
